<template>
  <div class="mt-28">
    <label for="comment" class="block text-xl font-semibold text-gray-900">{{loc.benefit_suggestion}}</label>
    <p class="text-sm mt-5">{{ loc.suggestion_desc }}</p>
    <div>
      <textarea style="height: 300px;" v-model="suggestion" rows="4" name="comment" id="comment" class="shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-300 rounded-md" />
    </div>
    <div class="flex flex-start">
      <button @click="send_suggestion" v-if="suggestion && !loading" type="button" :class="`bg-base-600 hover:bg-base-500 inline-flex mt-4 items-center px-5 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-base-500`">
        {{loc.send}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Suggestion",
  data() {
    return {
      suggestion: "",
      loading: false,
    }
  },
  methods: {
    convertLinksToHtml(text) {
      const urlRegex = /(\b(https?:\/\/|www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?)/g;

      return text.replace(urlRegex, (url) => {
        let hyperlink = url;

        if (!hyperlink.startsWith('http')) {
          hyperlink = 'https://' + hyperlink;
        }

        return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    },
    async send_suggestion() {
      this.loading = true
      try {
        await this.axios.post('v2/suggestion/', {
          text: this.convertLinksToHtml(this.suggestion)
        })
        this.set_success(this.loc.success_sending_suggestion)
        this.suggestion = ""
      } catch (e) {
        this.set_error("Error")
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>